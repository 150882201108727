/*
=====================================================================
*   Ceevee v1.0 Layout Stylesheet
*   url: styleshout.com
*   03-18-2014
=====================================================================

   TOC:
   a. General Styles
   b. Header Styles
   c. About Section
   d. Resume Section
   e. Portfolio Section
   f. Call To Action Section
   g. Testimonials Section
   h. Contact Section
   i. Footer

===================================================================== */

/* ------------------------------------------------------------------ */
/* a. General Styles
/* ------------------------------------------------------------------ */

body {
   background: #f3f2f9;
   color: #5f5f5f;
}

.loading {
   background: #191919 url(../images/loader.gif) no-repeat center center;
   margin: 0 auto;
   width: 100%;
   min-height: 500px;
   height: 100%;
}

/**
 * 14.0 - Error Page
 */
.page-404 {
   color: #000000;
   font-size: 30px;
   font-weight: 300;
   text-align: center;
   padding: 50px 0;
}

.page-404 p {
   margin-bottom: 60px;
}

.page-404 h2 {
   color: #d1d1d1;
   font-size: 170px;
   line-height: 1;
   font-weight: 700;
   margin-bottom: 40px;
}

.page-404 h2 span {
   margin: 0 10px;
   position: relative;
   display: inline-block;
   color: #429e9d;
}

.page-404 h2 span:before {
   content: '';
   width: 0;
   height: 0;
   left: 50%;
   bottom: 5px;
   display: block;
   margin-left: -10px;
   position: absolute;
   border-style: solid;
   border-width: 0px 0px 16px 17px;
   border-color: transparent;
   -webkit-transform: rotate(15deg);
   -moz-transform: rotate(15deg);
   transform: rotate(15deg);
   border-left-color: #429e9d;
}

.page-404 .btn {
   color: #a7a7a7 !important;
   width: 100%;
   max-width: 256px;
   display: block;
   margin: 0 auto;
}

.header-has-img .page-404 {
   margin-top: 170px;
}

.appear-enter {
   transition-duration: 20s;
   transition-property: opacity;
   transition-timing-function: ease-out;
   opacity: 0;
}

.appear-enter.appear-enter-active {
   opacity: 1;
}

.appear-leave {
   opacity: 0;
}

.bullet-point {
   font-size: 140%;
   vertical-align: middle;
}

/* ------------------------------------------------------------------ */
/* b. Header Styles
/* ------------------------------------------------------------------ */

#home {
   position: relative;
   height: 800px;
   min-height: 800px;
   width: 100%;
   text-align: center;
   overflow: hidden;
   background-color: #2a3a5a;
}

/* vertically center banner section */
#home:before {
   content: '';
   display: inline-block;
   vertical-align: middle;
   height: 100%;
}

#home .logo {
   position: absolute;
   background: url(../images/background-white.svg) no-repeat top center;
   background-size: contain !important;
   -webkit-background-size: contain !important;
   width: 100%;
   height: 400px;
   top: 0;
}

#home .banner {
   position: absolute;
   bottom: 80px;
   background-color: white;
   width: 100%;
   max-width: none;
}


#home .banner-content h2 {
   color: #2a2a2a;
   font-size: 44px;
   line-height: 1.1;
   margin-bottom: 20px;
   text-transform: capitalize;
}

/* header social links */
#home .social {
   margin: 24px 0;
   padding: 0;
   font-size: 30px;
   text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
}

#home .social li {
   display: inline-block;
   margin: 0 15px;
   padding: 0;
}

#home .social li a {
   color: #fff;
}

#home .social li a:hover {
   color: #11ABB0;
}

/* scrolldown link */
#home .scrolldown a {
   position: absolute;
   bottom: 30px;
   left: 50%;
   margin-left: -29px;
   color: #fff;
   display: block;
   height: 42px;
   width: 42px;
   font-size: 42px;
   line-height: 42px;
   color: #fff;
   border-radius: 100%;

   -webkit-transition: all .3s ease-in-out;
   -moz-transition: all .3s ease-in-out;
   -o-transition: all .3s ease-in-out;
   transition: all .3s ease-in-out;
}

#home .scrolldown a:hover {
   color: #11ABB0;
}


/* primary navigation
 --------------------------------------------------------------------- */
#nav-wrap ul,
#nav-wrap li,
#nav-wrap a {
   margin: 0;
   padding: 0;
   border: none;
   outline: none;
}

/* nav-wrap */
#nav-wrap {
   /*TODO font: 12px 'opensans-bold', sans-serif; */
   width: 100%;
   text-transform: uppercase;
   letter-spacing: 2.5px;
   margin: 0 auto;
   position: fixed;
   left: 0;
   top: 0;
   z-index: 1;
}



ul#nav {
   min-height: 48px;
   width: auto;
   /* center align the menu */
   text-align: center;
}

ul#nav {
   min-height: 48px;
   width: auto;

   /* center align the menu */
   text-align: center;
}

ul#nav li {
   position: relative;
   list-style: none;
   height: 48px;
   display: inline-block;
}



/* Links */
ul#nav li a {

   /* 8px padding top + 8px padding bottom + 32px line-height = 48px */

   display: inline-block;
   padding: 8px 13px;
   line-height: 32px;
   text-decoration: none;
   text-align: left;
   color: #fff;

   -webkit-transition: color .2s ease-in-out;
   -moz-transition: color .2s ease-in-out;
   -o-transition: color .2s ease-in-out;
   -ms-transition: color .2s ease-in-out;
   transition: color .2s ease-in-out;
}


ul#nav li a:active {
   background-color: transparent !important;
}

.opaque {
   background-color: #2a3a5a;
}

/* hide toggle button */
#nav-wrap>button {
   display: none;
}

ul#nav li.current a {
   color: #F06000;
}


/* ------------------------------------------------------------------ */
/* c. About Section
 /* ------------------------------------------------------------------ */

#about {
   background: #D9E6E5;
   padding-top: 30px;
   padding-bottom: 30px;
   overflow: hidden;
}

#about a,
#about a:visited {
   color: #313131;
}

#about a:hover,
#about a:focus {
   color: #11ABB0;
}

#about h2 {
   font: 22px/30px 'opensans-bold', sans-serif;
   color: #313131;
   margin-bottom: 12px;
}

#about p {
   line-height: 30px;
   color: #7A7A7A;
   text-align: justify;
}

#about .profile-pic {
   position: relative;
   width: 120px;
   height: 120px;
   border-radius: 100%;
}

#about .contact-details {
   width: 41.66667%;
}

#about .download {
   padding-top: 20px;
   text-align: center;
   background: #fff;
}

#about .main-col {
   padding-right: 5%;
}

#about .download .button {
   margin-top: 6px;
   background: #444;
   color: #ffff;
}

#about .download .button:hover {
   background: #11ABB0;
   color: #fff;
}

#about .download .button i {
   margin-right: 15px;
   font-size: 20px;
}

strong.fa-quora {
   /*TODO font: 100% serif; */
}


/* ------------------------------------------------------------------ */
/* d. Work Section
 /* ------------------------------------------------------------------ */

#work {
   background: #fff;
   padding-top: 90px;
   padding-bottom: 0px;
   overflow: hidden;
}

#work a,
#work a:visited {
   color: #313131;
}

#work a:hover,
#work a:focus {
   color: #11ABB0;
}

#work h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
}

#work h1 span {
   border-bottom: 3px solid #11ABB0;
   padding-bottom: 6px;
}

#work h3 {
   font: 25px/30px 'opensans-bold', sans-serif;
}

#work .header-col {
   padding-top: 9px;
}

#work .main-col {
   padding-right: 10%;
}

#work .info {
   font: 15px/24px 'librebaskerville-regular', sans-serif;
   color: #6E7881;
   margin-bottom: 18px;
   margin-top: 9px;
}

#work .info span {
   margin-right: 5px;
   margin-left: 5px;
}

#work .info-summary {
   font: 15px/24px 'librebaskerville-regular', sans-serif;
   margin-top: 6px;
}

#work .date {
   font: 15px/24px 'librebaskerville-regular', sans-serif;
   margin-top: 6px;
}

#work .point {
   margin: 0 0 10px 0;
}


/* ------------------------------------------------------------------ */
/* d. Education Section
/* ------------------------------------------------------------------ */

#education {
   background: #fff;
   padding-top: 72px;
   padding-bottom: 0px;
   overflow: hidden;
}

#education a,
#education a:visited {
   color: #11ABB0;
}

#education a:hover,
#education a:focus {
   color: #313131;
}

#education h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
}

#education h1 span {
   border-bottom: 3px solid #11ABB0;
   padding-bottom: 6px;
}

#education h3 {
   font: 25px/30px 'opensans-bold', sans-serif;
}

#education .header-col {
   padding-top: 9px;
}

#education .main-col {
   padding-right: 10%;
}

#education .info {
   font: 15px/24px 'librebaskerville-regular', sans-serif;
   color: #6E7881;
   margin-bottom: 18px;
   margin-top: 9px;
}

#education .info span {
   margin-right: 5px;
   margin-left: 5px;
}

#education .info-summary {
   font: 15px/24px 'librebaskerville-regular', sans-serif;
   margin-top: 6px;
}

#education .date {
   font: 15px/24px 'librebaskerville-regular', sans-serif;
   margin-top: 6px;
}

#education .point {
   margin: 0 0 10px 0;
}



/* ------------------------------------------------------------------ */
/* d. Publication Section
/* ------------------------------------------------------------------ */

#publication {
   background: #fff;
   padding-top: 72px;
   padding-bottom: 0px;
   overflow: hidden;
}

#publication a,
#publication a:visited {
   color: #11ABB0;
}

#publication a:hover,
#publication a:focus {
   color: #313131;
}

#publication h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
}

#publication h1 span {
   border-bottom: 3px solid #11ABB0;
   padding-bottom: 6px;
}

#publication h3 {
   font: 25px/30px 'opensans-bold', sans-serif;
}

#publication .header-col {
   padding-top: 9px;
}

#publication .main-col {
   padding-right: 10%;
}

#publication .info {
   font: 15px/24px 'librebaskerville-regular', sans-serif;
   color: #6E7881;
   margin-bottom: 18px;
   margin-top: 9px;
}

#publication .info span {
   margin-right: 5px;
   margin-left: 5px;
}

#publication .info-summary {
   font: 15px/24px 'librebaskerville-regular', sans-serif;
   margin-top: 6px;
}

#publication .date {
   font: 15px/24px 'librebaskerville-regular', sans-serif;
   margin-top: 6px;
}

#publication .point {
   margin: 0 0 10px 0;
}


/* ------------------------------------------------------------------ */
/* d. Expertise Section
/* ------------------------------------------------------------------ */
#expertise {
   background: #fff;
   padding-top: 72px;
   padding-bottom: 0px;
   overflow: hidden;
}

#expertise h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
}

#expertise h1 span {
   border-bottom: 3px solid #11ABB0;
   padding-bottom: 6px;
}


.expertise-list {
   list-style: none;
   counter-reset: ctr 0;
}

.expertise-list h5 {
   font: 25px/30px 'opensans-bold', sans-serif;
}

.expertise-item-header {
   padding-bottom: 2.4rem;
   padding-right: 6rem;
   cursor: pointer;
   position: relative;
}

.expertise-item-header h5 {
   font-family: "Gothic A1", sans-serif;
   font-weight: 400;
   font-size: 2.6rem;
   padding-left: 1.111em;
   margin: 0px;
   position: relative;
}

.expertise-item-header h5::before {
   content: counter(ctr, decimal-leading-zero) ".";
   counter-increment: ctr 1;
   font-family: "Work Sans", sans-serif;
   font-weight: 600;
   font-size: 0.5em;
   line-height: 1;
   color: #11ABB0;
   position: absolute;
   top: 0.5em;
   left: 0px;
}

.expertise-item-header::after {
   border-bottom: 2px solid #11ABB0;
   border-right: 2px solid #11ABB0;
   content: "";
   display: block;
   height: 6px;
   width: 6px;
   margin-top: -6px;
   pointer-events: none;
   position: absolute;
   right: 2.4rem;
   top: 50%;
   transform-origin: 66% 66%;
   transition: all 0s ease 0s, all 0.3s ease 0s;
   transform: rotate(45deg);
}

.expertise-item-body {
   padding-right: 4rem;
   display: none;
}

.is-active .expertise-item-header::after {
   transform: rotate(225deg);
}

.is-active .expertise-item-body {
   display: block;
}


/* ------------------------------------------------------------------ */
/* d. Skill Section
/* ------------------------------------------------------------------ */

#skill {
   background: #fff;
   padding-top: 72px;
   padding-bottom: 20px;
   overflow: hidden;
}

#skill a,
#skill a:visited {
   color: #11ABB0;
}

#skill a:hover,
#skill a:focus {
   color: #313131;
}

#skill h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
}

#skill h1 span {
   border-bottom: 3px solid #11ABB0;
   padding-bottom: 6px;
}

#skill h3 {
   font: 25px/30px 'opensans-bold', sans-serif;
}

#skill .header-col {
   padding-top: 9px;
}

#skill .main-col {
   padding-right: 10%;
}

#skill .info {
   font: 16px/24px 'librebaskerville-italic', serif;
   color: #6E7881;
   margin-bottom: 18px;
   margin-top: 9px;
}

#skill .info span {
   margin-right: 5px;
   margin-left: 5px;
}

#skill .date {
   font: 15px/24px 'opensans-regular', sans-serif;
   margin-top: 6px;
}

#skill .point {
   margin: 0 0 10px 0;
}

#skill .inside {
   padding: 0 20px;
}

#skill .skill-summary {
   margin: 0 0 10px 0;
}

.skill,
.education,
.work,
.projects,
.publication {
   margin-bottom: 48px;
   border-bottom: 1px solid #E8E8E8;
}

/*----------------------------------------------*/
/*	Skill Bars
 /*----------------------------------------------*/

.bars {
   width: 95%;
   float: left;
   padding: 0;
   text-align: left;
}

.bars .skills {
   margin-top: 36px;
   list-style: none;
}

.bars li {
   position: relative;
   margin-bottom: 40px;
   background: #ccc;
   height: 12px;
   border-radius: 3px;
}

.bars li em {
   font: 13px 'opensans-bold', sans-serif;
   font-weight: normal;
   text-transform: uppercase;
   position: relative;
   top: -35px;
   display: inline-block;
}

.bar-expand {
   position: absolute;
   left: 0;
   top: 0;
   margin: 0;
   padding-right: 24px;
   background: #313131;
   display: inline-block;
   height: 12px;
   line-height: 32px;
   border-radius: 3px 0 0 3px;
}

.percentage50 {
   width: 50%;
   -moz-animation: percentage50 2s ease;
   -webkit-animation: percentage50 2s ease;
}

.percentage55 {
   width: 55%;
   -moz-animation: percentage55 2s ease;
   -webkit-animation: percentage55 2s ease;
}

.percentage60 {
   width: 60%;
   -moz-animation: percentage60 2s ease;
   -webkit-animation: percentage60 2s ease;
}

.percentage65 {
   width: 65%;
   -moz-animation: percentage65 2s ease;
   -webkit-animation: percentage65 2s ease;
}

.percentage70 {
   width: 75%;
   -moz-animation: percentage70 2s ease;
   -webkit-animation: percentage70 2s ease;
}

.percentage75 {
   width: 75%;
   -moz-animation: percentage75 2s ease;
   -webkit-animation: percentage75 2s ease;
}

.percentage80 {
   width: 80%;
   -moz-animation: percentage80 2s ease;
   -webkit-animation: percentage80 2s ease;
}

.percentage85 {
   width: 85%;
   -moz-animation: percentage85 2s ease;
   -webkit-animation: percentage85 2s ease;
}

.percentage90 {
   width: 90%;
   -moz-animation: percentage90 2s ease;
   -webkit-animation: percentage90 2s ease;
}

.percentage95 {
   width: 95%;
   -moz-animation: percentage95 2s ease;
   -webkit-animation: percentage95 2s ease;
}

.percentage100 {
   width: 100%;
   -moz-animation: percentage100 2s ease;
   -webkit-animation: percentage100 2s ease;
}

@-moz-keyframes percentage50 {
   0% {
      width: 0px;
   }

   100% {
      width: 50%;
   }
}

@-moz-keyframes percentage55 {
   0% {
      width: 0px;
   }

   100% {
      width: 55%;
   }
}

@-moz-keyframes percentage60 {
   0% {
      width: 0px;
   }

   100% {
      width: 60%;
   }
}

@-moz-keyframes percentage65 {
   0% {
      width: 0px;
   }

   100% {
      width: 65%;
   }
}

@-moz-keyframes percentage70 {
   0% {
      width: 0px;
   }

   100% {
      width: 70%;
   }
}

@-moz-keyframes percentage75 {
   0% {
      width: 0px;
   }

   100% {
      width: 75%;
   }
}

@-moz-keyframes percentage80 {
   0% {
      width: 0px;
   }

   100% {
      width: 80%;
   }
}

@-moz-keyframes percentage85 {
   0% {
      width: 0px;
   }

   100% {
      width: 85%;
   }
}

@-moz-keyframes percentage90 {
   0% {
      width: 0px;
   }

   100% {
      width: 90%;
   }
}

@-moz-keyframes percentage95 {
   0% {
      width: 0px;
   }

   100% {
      width: 95%;
   }
}

@-moz-keyframes percentage100 {
   0% {
      width: 0px;
   }

   100% {
      width: 100%;
   }
}

@-webkit-keyframes percentage50 {
   0% {
      width: 0px;
   }

   100% {
      width: 50%;
   }
}

@-webkit-keyframes percentage55 {
   0% {
      width: 0px;
   }

   100% {
      width: 55%;
   }
}

@-webkit-keyframes percentage60 {
   0% {
      width: 0px;
   }

   100% {
      width: 60%;
   }
}

@-webkit-keyframes percentage65 {
   0% {
      width: 0px;
   }

   100% {
      width: 65%;
   }
}

@-webkit-keyframes percentage70 {
   0% {
      width: 0px;
   }

   100% {
      width: 70%;
   }
}

@-webkit-keyframes percentage75 {
   0% {
      width: 0px;
   }

   100% {
      width: 75%;
   }
}

@-webkit-keyframes percentage80 {
   0% {
      width: 0px;
   }

   100% {
      width: 80%;
   }
}

@-webkit-keyframes percentage85 {
   0% {
      width: 0px;
   }

   100% {
      width: 85%;
   }
}

@-webkit-keyframes percentage90 {
   0% {
      width: 0px;
   }

   100% {
      width: 90%;
   }
}

@-webkit-keyframes percentage95 {
   0% {
      width: 0px;
   }

   100% {
      width: 95%;
   }
}

@-webkit-keyframes percentage100 {
   0% {
      width: 0px;
   }

   100% {
      width: 100%;
   }
}


/* ------------------------------------------------------------------ */
/* e. Project Section
/* ------------------------------------------------------------------ */

#project {
   background: #ebeeee;
   padding-top: 90px;
   padding-bottom: 60px;
}

#project h1 {
   font: 18px/24px 'opensans-semibold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
   text-align: center;
   margin-bottom: 48px;
   color: #95A3A3;
}

/* Project Content */
#project-wrapper .columns {
   margin-bottom: 36px;
}

.project-item .item-wrap {
   background: #fff;
   overflow: hidden;
   position: relative;

   -webkit-transition: all 0.3s ease-in-out;
   -moz-transition: all 0.3s ease-in-out;
   -o-transition: all 0.3s ease-in-out;
   -ms-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
}

.project-item .item-wrap a {
   display: block;
   cursor: pointer;
}

/* overlay */
.project-item .item-wrap .overlay {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;

   opacity: 0;
   -moz-opacity: 0;
   filter: alpha(opacity=0);

   -webkit-transition: opacity 0.3s ease-in-out;
   -moz-transition: opacity 0.3s ease-in-out;
   -o-transition: opacity 0.3s ease-in-out;
   transition: opacity 0.3s ease-in-out;

   background: url(../images/overlay-bg.png) repeat;
}

.project-item .item-wrap .link-icon {
   display: block;
   color: #fff;
   height: 30px;
   width: 30px;
   font-size: 18px;
   line-height: 30px;
   text-align: center;

   opacity: 0;
   -moz-opacity: 0;
   filter: alpha(opacity=0);

   -webkit-transition: opacity 0.3s ease-in-out;
   -moz-transition: opacity 0.3s ease-in-out;
   -o-transition: opacity 0.3s ease-in-out;
   transition: opacity 0.3s ease-in-out;

   position: absolute;
   top: 50%;
   left: 50%;
   margin-left: -15px;
   margin-top: -15px;
}

.project-item .item-wrap img {
   vertical-align: bottom;
}

.project-item .project-item-meta {
   padding: 18px
}

.project-item .project-item-meta h5 {
   font: 14px/21px 'opensans-bold', sans-serif;
   color: #fff;
}

.project-item .project-item-meta p {
   font: 12px/18px 'opensans-light', sans-serif;
   color: #c6c7c7;
   margin-bottom: 0;
}

/* on hover */
.project-item:hover .overlay {
   opacity: 1;
   -moz-opacity: 1;
   filter: alpha(opacity=100);
}

.project-item:hover .link-icon {
   opacity: 1;
   -moz-opacity: 1;
   filter: alpha(opacity=100);
}

/* popup modal */
.popup-modal {
   max-width: 500px;
   max-height: 90%;
   overflow: auto;
   background: #fff;
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%)
}

.popup-modal .description-box {
   padding: 12px 36px 18px 36px;
}

.popup-modal .description-box h4 {
   font: 15px/24px 'opensans-bold', sans-serif;
   margin-bottom: 12px;
   color: #111;
}

.popup-modal .description-box p {
   font: 14px/24px 'opensans-regular', sans-serif;
   color: #838C95;
   margin-bottom: 12px;
}

.popup-modal .description-box .categories {
   font: 12px/21px 'opensans-light', sans-serif;
   color: #838C95;
   text-transform: uppercase;
   letter-spacing: 2px;
   display: block;
   text-align: left;
}

.popup-modal .description-box .categories i {
   margin-right: 8px;
}

.popup-modal .link-box {
   padding: 18px 36px;
   background: #111;
   text-align: left;
}

.popup-modal .link-box a {
   color: #fff;
   font: 11px/21px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   cursor: pointer;
}

.popup-modal a:hover {
   color: #00CCCC;
}

.popup-modal a.popup-modal-dismiss {
   margin-left: 24px;
}


/* fadein/fadeout effect for modal popup
 /* ------------------------------------------------------------------ */

/* content at start */
.mfp-fade.mfp-wrap .mfp-content .popup-modal {
   opacity: 0;
   -webkit-transition: all 200ms ease-in-out;
   -moz-transition: all 200ms ease-in-out;
   -o-transition: all 200ms ease-in-out;
   -ms-transition: all 200ms ease-in-out;
   transition: all 200ms ease-in-out;
}

/* content fadein */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content .popup-modal {
   opacity: 1;
}

/* content fadeout */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content .popup-modal {
   opacity: 0;
}

/* ------------------------------------------------------------------ */
/* f. Call To Action Section
 /* ------------------------------------------------------------------ */

#call-to-action {
   background: #212121;
   padding-top: 66px;
   padding-bottom: 48px;
}

#call-to-action h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
}

#call-to-action h1 span {
   display: none;
}

#call-to-action .header-col h1:before {
   font-family: 'FontAwesome';
   content: "\f0ac";
   padding-right: 10px;
   font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #fff;
}

#call-to-action .action {
   margin-top: 12px;
}

#call-to-action h2 {
   font: 28px/36px 'opensans-bold', sans-serif;
   color: #EBEEEE;
   margin-bottom: 6px;
}

#call-to-action h2 a {
   color: inherit;
}

#call-to-action p {
   color: #636363;
   font-size: 17px;
}

/*#
 call-to-action .button {
     color:#fff;
    background: #0D0D0D;
 }
 */
#call-to-action .button:hover,
#call-to-action .button:active {
   background: #FFFFFF;
   color: #0D0D0D;
}

#call-to-action p span {
   font-family: 'opensans-semibold', sans-serif;
   color: #D8D8D8;
}

/* ------------------------------------------------------------------
 /* g. Testimonials
 /* ------------------------------------------------------------------ */

#testimonials {
   /*background: #1F1F1F url(../images/background.jpg) no-repeat center right;*/
   background: #2B2B2B;
   min-height: 200px;
   width: 100%;
   overflow: hidden;
}

#testimonials .text-container {
   padding-top: 96px;
   padding-bottom: 66px;
}

#testimonials h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
}

#testimonials h1 span {
   display: none;
}

#testimonials .header-col {
   padding-top: 9px;
}

#testimonials .header-col h1:before {
   font-family: 'FontAwesome';
   content: "\f10d";
   padding-right: 10px;
   font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #fff;
}

/*	Blockquotes */
#testimonials blockquote {
   margin: 0 0px 30px 0px;
   padding-left: 0;
   position: relative;
   text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
}

#testimonials blockquote:before {
   content: none;
}

#testimonials blockquote p {
   font-family: 'librebaskerville-italic', serif;
   padding: 0;
   font-size: 18px;
   line-height: 48px;
   color: #fff
}

#testimonials blockquote cite {
   display: block;
   font-size: 12px;
   font-style: normal;
   line-height: 18px;
   color: #fff;
}

#testimonials blockquote cite:before {
   content: "\2014 \0020";
}

#testimonials blockquote cite a,
#testimonials blockquote cite a:visited {
   color: #8B9798;
   border: none
}

/* Flex Slider
 /* ------------------------------------------------------------------ */

/* Reset */
.flexslider a:active,
.flexslider a:focus {
   outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
   margin: 0;
   padding: 0;
   list-style: none;
}

.slides li {
   margin: 0;
   padding: 0;
}

/* Necessary Styles */
.flexslider {
   position: relative;
   zoom: 1;
   margin: 0;
   padding: 0;
}

.flexslider .slides {
   zoom: 1;
}

.flexslider .slides>li {
   position: relative;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides>li {
   display: none;
   -webkit-backface-visibility: hidden;
}

/* Suggested container for slide animation setups. Can replace this with your own */
.flex-container {
   zoom: 1;
   position: relative;
   width: 82%;
}

/* Clearfix for .slides */
.slides:before,
.slides:after {
   content: " ";
   display: table;
}

.slides:after {
   clear: both;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
  * include js that eliminates this class on page load */
.no-js .slides>li:first-child {
   display: block;
}

/* Slider Styles */
.slides {
   zoom: 1;
}

.slides>li {
   /*margin-right: 5px; */
   overflow: hidden;
}

/* Control Nav */
.flex-control-nav {
   width: 100%;
   position: absolute;
   bottom: -20px;
   text-align: left;
}

.flex-control-nav li {
   margin: 0 6px;
   display: inline-block;
   zoom: 1;
}

.flex-control-paging li a {
   width: 12px;
   height: 12px;
   display: block;
   background: #ddd;
   background: rgba(255, 255, 255, .3);
   cursor: pointer;
   text-indent: -9999px;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   -o-border-radius: 20px;
   border-radius: 20px;
   box-shadow: inset 0 0 3px rgba(255, 255, 255, .3);
}

.flex-control-paging li a:hover {
   background: #CCC;
   background: rgba(255, 255, 255, .7);
}

.flex-control-paging li a.flex-active {
   background: #fff;
   background: rgba(255, 255, 255, .9);
   cursor: default;
}

/* ------------------------------------------------------------------ */
/* h. Contact Section
 /* ------------------------------------------------------------------ */

#contact {
   padding-top: 96px;
   padding-bottom: 102px;
   color: #636363;
}

#contact .section-head {
   margin-bottom: 42px;
}

#contact a,
#contact a:visited {
   color: #11ABB0;
}

#contact a:hover,
#contact a:focus {
   color: #fff;
}

#contact h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #EBEEEE;
   margin-bottom: 6px;
}

#contact h1 span {
   display: none;
}

#contact h1:before {
   font-family: 'FontAwesome';
   content: "\f0e0";
   padding-right: 10px;
   font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #ebeeee;
}

#contact h4 {
   font: 16px/24px 'opensans-bold', sans-serif;
   color: #EBEEEE;
   margin-bottom: 6px;
}

#contact p.lead {
   font: 18px/36px 'opensans-light', sans-serif;
   padding-right: 3%;
}

#contact .header-col {
   padding-top: 6px;
}


/* contact form */

#contact form {
   margin-bottom: 30px;
}

#contact input,
textarea {
   background-color: #fafafa;
   border: 1px solid #a9a9a9;
   box-sizing: border-box;
   color: #000;
   height: 46px;
   padding: 10px;
   position: relative;
   margin-bottom: 0px;
   width: 100%;
}

#contact p {
   margin: 0 0 4px;
}

#contact .option label {
   align-items: center;
   box-sizing: border-box;
   display: inline-flex;
   cursor: pointer;
   column-gap: 6px;
   text-rendering: optimizelegibility;
   margin: 6px 0 4px;
   font-size: 13px;
}

#contact .option input {
   width: 16px;
}

#contact .caption-text {
   font-size: 12px;
}

#contact .description {
   padding: 0.5em 0 0.5em;
   font-size: 12px;
   opacity: .7;
   display: block;
   white-space: pre-wrap;
}

#contact input:focus,
#contact textarea:focus,
#contact select:focus {
   color: #fff;
   background-color: #11ABB0;
}

#contact button.submit {
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
   background: #0D0D0D;
   border: none;
   cursor: pointer;
   height: auto;
   display: inline-block;
   border-radius: 3px;
   margin-left: 26%;
}

#contact button {
   margin-top: 20px;
}


#contact .title div {
   align-items: center;
   column-gap: 1ex;
   display: flex;
   flex-wrap: wrap;
   font-size: 16px;
}

#contact span.required {
   color: #11ABB0;
   font-size: 13px;
   align-items: center;
   column-gap: 1ex;
   display: flex;
   flex-wrap: wrap;
}




#message-warning,
#message-success {
   display: none;
   background: #0F0F0F;
   padding: 24px 24px;
   margin-bottom: 36px;
   width: 65%;
   margin-left: 26%;
}

#message-warning {
   color: #D72828;
}

#message-success {
   color: #11ABB0;
}

#message-warning i,
#message-success i {
   margin-right: 10px;
}

#image-loader {
   display: none;
   position: relative;
   left: 18px;
   top: 17px;
}


/* Twitter Feed */
#twitter {
   margin-top: 12px;
   padding: 0;
}

#twitter li {
   margin: 6px 0px 12px 0;
   line-height: 30px;
}

#twitter li span {
   display: block;
}

#twitter li b a {
   font: 13px/36px 'opensans-regular', Sans-serif;
   color: #474747 !important;
   border: none;
}


/* ------------------------------------------------------------------ */
/* i. Footer
 /* ------------------------------------------------------------------ */

footer {
   padding-top: 48px;
   padding-bottom: 45px;
   font-size: 14px;
   text-align: center;
   position: relative;
   color: #ffff;
   background-color: #2a3a5a;
}

footer a,
footer a:visited {
   color: #ffc95c;
}

footer a:hover,
footer a:focus {
   color: #11ABB0;
}

/* copyright */
footer .copyright {
   margin: 0;
   padding: 0;
}

footer .copyright li {
   display: inline-block;
   margin: 0;
   padding: 0;
   line-height: 24px;
}

.ie footer .copyright li {
   display: inline;
}

footer .copyright li:before {
   content: "\2022";
   padding-left: 10px;
   padding-right: 10px;
   color: #095153;
}

footer .copyright li:first-child:before {
   display: none;
}

/* social links */
footer .social-links {
   margin: 18px 0 30px 0;
   padding: 0;
   font-size: 30px;
}

footer .social-links a {
   color: #fff;
}

footer .social-links li {
   display: inline-block;
   margin: 0;
   padding: 0;
   margin-left: 42px;
   color: #F06000;
}

footer .social-links li:first-child {
   margin-left: 0;
}

/* Go To Top Button */
#go-top {
   position: absolute;
   top: -24px;
   left: 50%;
   margin-left: -30px;
}

#go-top a {
   text-decoration: none;
   border: 0 none;
   display: block;
   width: 60px;
   height: 60px;
   background-color: #525252;

   -webkit-transition: all 0.2s ease-in-out;
   -moz-transition: all 0.2s ease-in-out;
   -o-transition: all 0.2s ease-in-out;
   -ms-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;

   color: #fff;
   font-size: 21px;
   line-height: 60px;
   border-radius: 100%;
}

#go-top a:hover {
   background-color: #0F9095;
}


/* ------------------------------------------------------------------ */
/* Timeline
 /* ------------------------------------------------------------------ */

.timeline-title {
   color: #414141 !important;
   line-height: 1.75rem !important;
   text-align: center !important;
   font: 25px/30px 'opensans-bold', sans-serif !important;
}

.card-sub-title {
   color: #0D0A0B !important;
   line-height: 1.1 !important;
   font-size: 1.4rem !important;
   font-style: normal !important;
   font-weight: normal !important;
   text-rendering: optimizeLegibility !important;
   text-align: center !important;
}

.timeline-item-title {
   font-size: 1.4rem !important;
   line-height: 1.5 !important;
   text-transform: uppercase !important;
   letter-spacing: .15rem !important;
   color: rgba(0, 0, 0, 0.5) !important;
}

.hire {
   text-align: center;
}

.hire h1 {
   font-size: 2.9rem;
}

.ribbon-tag {
   display: inline-block;
   text-align: center;
   font-weight: 400;
   line-height: 1.5;
   white-space: nowrap;
   vertical-align: middle;
   height: 30px;
   background: white;
   color: #ef172c;
   border-radius: 2px;
   border: 1px solid #ef172c;
   margin-right: 10px;
   margin-bottom: 10px;
   -webkit-box-shadow: 0px 0px 2px rgba(255, 0, 0, 0.3);
   -moz-box-shadow: 0px 0px 2px rgba(255, 0, 0, 0.3);
   box-shadow: 0px 0px 2px rgba(255, 0, 0, 0.3);
   position: relative;
   padding: 4px 30px;
   font-size: 12px;
}

.ribbon-wrapper-80,
.ribbon-wrapper-90,
.ribbon-wrapper-100 {

   font: bold 6px Sans-Serif;
   text-align: center;
   width: 54px;
   height: 34px;
   overflow: hidden;
   position: absolute;
   top: -3px;
   right: -3px;
}


.ribbon-80 {
   color: #fff;
   text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
   -webkit-transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   -o-transform: rotate(45deg);
   position: relative;
   padding: 2px 0;
   left: -9px;
   top: 12px;
   width: 70px;
   background-color: #965A38;
   background-image: -webkit-gradient(linear, left top, left bottom, from(#965A38), to(#CD7F32));
   background-image: -webkit-linear-gradient(top, #965A38, #CD7F32);
   background-image: -moz-linear-gradient(top, #965A38, #CD7F32);
   background-image: -ms-linear-gradient(top, #965A38, #CD7F32);
   background-image: -o-linear-gradient(top, #965A38, #CD7F32);
   -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
   -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
   box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.ribbon-90 {
   color: #313131;
   text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
   -webkit-transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   -o-transform: rotate(45deg);
   position: relative;
   padding: 1px 0;
   left: 10px;
   top: 8px;
   width: 60px;
   background-color: #B6B5B8;
   background-image: -webkit-gradient(linear, left top, left bottom, from(#C0C0C0), to(#000000));
   background-image: -webkit-linear-gradient(top, #C0C0C0, #000000);
   background-image: -moz-linear-gradient(top, #C0C0C0, #000000);
   background-image: -ms-linear-gradient(top, #C0C0C0, #000000);
   background-image: -o-linear-gradient(top, #C0C0C0, #000000);
   -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
   -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
   box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.ribbon-100 {
   color: #000000;
   text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
   -webkit-transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   -o-transform: rotate(45deg);
   position: relative;
   padding: 1px 0;
   left: 10px;
   top: 8px;
   width: 60px;
   background-color: #FFCA09;
   background-image: -webkit-gradient(linear, left top, left bottom, from(#FFCA09), to(#BAA445));
   background-image: -webkit-linear-gradient(top, #FFCA09, #BAA445);
   background-image: -moz-linear-gradient(top, #FFCA09, #BAA445);
   background-image: -ms-linear-gradient(top, #FFCA09, #BAA445);
   background-image: -o-linear-gradient(top, #FFCA09, #BAA445);
   -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
   -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
   box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.ribbon-80:before,
.ribbon-80:after {
   content: "";
   border-top: 3px solid #b90005;
   border-left: 3px solid transparent;
   border-right: 3px solid transparent;
   position: absolute;
   bottom: -3px;
}

.ribbon-90:after,
.ribbon-90:after {
   content: "";
   border-top: 3px solid #6e8900;
   border-left: 3px solid transparent;
   border-right: 3px solid transparent;
   position: absolute;
   bottom: -3px;
}

.ribbon-100:after,
.ribbon-100:after {
   content: "";
   border-top: 3px solid #8dc735;
   border-left: 3px solid transparent;
   border-right: 3px solid transparent;
   position: absolute;
   bottom: -3px;
}

.ribbon-80:before {
   left: 0;
}

.ribbon-90:before {
   right: 0;
}

.ribbon-100:before {
   right: 0;
}

.ribbon-80:after {
   right: 0;
}

.ribbon-90:after {
   left: 0;
}

.ribbon-100:after {
   left: 0;
}

.jokes-logo {
   position: absolute;
   background: url(../images/background-white.svg) no-repeat top center;
   background-size: contain !important;
   -webkit-background-size: contain !important;
   width: 100%;
   height: 200px;
   top: 0;
}

.jokes-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   position: absolute;
   top: 150px;
   width: 100%;
}
.jokes-container p {
   color:#FFFFFF
}

.jokes-display {
   margin-top: 5px;
    width: 100%;
}
.jokes-display .question {
  font-size: x-large;
  color:#ffc95c;
}
.jokes-display .anwser {
   font-size: x-large;
   color:#ffc95c;
}